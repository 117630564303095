import React, { useContext } from 'react';
import './CartItems.css';
import remove_icon from '../Assets/cart_cross_icon.png';
import { ShopContext } from '../../Context/ShopContext';

const CartItems = () => {
  const { all_products, cartItems, removeFromCart, getTotalCartAmount } = useContext(ShopContext);

  return (
    <div className='cartitems'>
      <div className="cartitems-format-main">
        <p>Product</p>
        <p>Title</p>
        <p>Price</p>
        <p>Size</p>
        <p>Quantity</p>
        <p>Total</p>
        <p>Remove</p>
      </div>
      <hr />
      {all_products.map((product) => {
        const sizesInCart = Object.keys(cartItems[product.id] || {});
        return sizesInCart.map((size) => {
          const quantity = cartItems[product.id][size];
          if (quantity > 0) {
            return (
              <div key={`${product.id}-${size}`} className="cartitems-format cartitems-format-main">
                <img src={product.image} alt="" className='carticon-product-icon' />
                <p>{product.name}</p>
                <p>${product.new_price}</p>
                <p>{size}</p>
                <button className='cartitems-quantity'>{quantity}</button>
                <p>${product.new_price * quantity}</p>
                <img style={{ cursor: 'pointer' }} src={remove_icon} onClick={() => { removeFromCart(product.id, size) }} alt="" />
              </div>
            );
          }
          return null;
        });
      })}
      <div className="cartitems-down">
        <div className="cartitems-total">
          <h1>Cart Total</h1>
          <div>
            <div className="cartitems-total-item">
              <p>Subtotal</p>
              <p>${getTotalCartAmount()}</p>
            </div>
            <hr />
            <div className="cartitems-total-item">
              <p>Shipping free</p>
              <p>Free</p>
            </div>
            <hr />
            <div className="cartitems-total-item">
              <h3>Total</h3>
              <h3>${getTotalCartAmount()}</h3>
            </div>
          </div>
          <button>PROCEED TO CHECKOUT</button>
        </div>
      </div>
    </div>
  );
}

export default CartItems;
