import React from 'react'
import './Footer.css'
import logo from "../Assets/logo.png";

const Footer = () => {
  return (
    <footer className="footer">
    <div className="footer-top section">
      <div className="container">
        <div className="footer-brand">
          <a style={{textDecoration:'none'}} href="#" className="logo">
          <img src={logo} alt="" />
        <p className="pi">Tiatlux</p>
          </a>
          <ul className="social-list">
            <li style={{listStyle:'none'}}>
              <a style={{textDecoration:'none'}} href="#" className="social-link">
                <ion-icon name="logo-facebook" />
              </a>
            </li>
            <li style={{listStyle:'none'}}>
              <a href="#" className="social-link">
                <ion-icon name="logo-twitter" />
              </a>
            </li>
            <li style={{listStyle:'none'}}>
              <a style={{textDecoration:'none'}} href="#" className="social-link">
                <ion-icon name="logo-pinterest" />
              </a>
            </li>
            <li style={{listStyle:'none'}}>
              <a style={{textDecoration:'none'}} href="#" className="social-link">
                <ion-icon name="logo-linkedin" />
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-link-box">
          <ul className="footer-list">
            <li style={{listStyle:'none'}}>
              <p className="footer-list-title">Contact Us</p>
            </li>
            <li style={{listStyle:'none'}}>
              <address className="footer-link">
                <ion-icon name="location" />
                <span className="footer-link-text">
                  2751 S Ikorodu Rd, Ikeja, CO 80014, Lagos State
                </span>
              </address>
            </li>
            <li style={{listStyle:'none'}}>
              <a style={{textDecoration:'none'}} href="tel:+2348095807602" className="footer-link">
                <ion-icon name="call" />
                <span className="footer-link-text">+2348095807602</span>
              </a>
            </li>
            <li style={{listStyle:'none'}}>
              <a style={{textDecoration:'none'}} href="mailto:houseoffiat@gmail.com" className="footer-link">
                <ion-icon name="mail" />
                <span className="footer-link-text">Dele.fapson@tiatlux.com</span>
              </a>
            </li>
          </ul>
          <ul className="footer-list">
            <li style={{listStyle:'none'}}>
              <p className="footer-list-title">Quick Links</p>
            </li>
          
          
            <li style={{listStyle:'none'}}>
              <a style={{textDecoration:'none'}} href="#" className="footer-link">
                <ion-icon name="chevron-forward-outline" />
                <span className="footer-link-text">Shirt</span>
              </a>
            </li>
            <li style={{listStyle:'none'}}>
              <a style={{textDecoration:'none'}} href="#" className="footer-link">
                <ion-icon name="chevron-forward-outline" />
                <span className="footer-link-text">Footwear</span>
              </a>
            </li>
            <li style={{listStyle:'none'}}>
              <a style={{textDecoration:'none'}} href="#" className="footer-link">
                <ion-icon name="chevron-forward-outline" />
                <span className="footer-link-text">New Products</span>
              </a>
            </li>
          </ul>
          <div className="footer-list">
            <p className="footer-list-title">Opening Time</p>
            <table className="footer-table">
              <tbody>
                <tr className="table-row">
                  <th className="table-head" scope="row">
                    Mon - Tue:
                  </th>
                  <td className="table-data">8AM - 10PM</td>
                </tr>
                <tr className="table-row">
                  <th className="table-head" scope="row">
                    Wed:
                  </th>
                  <td className="table-data">8AM - 7PM</td>
                </tr>
                <tr className="table-row">
                  <th className="table-head" scope="row">
                    Fri:
                  </th>
                  <td className="table-data">7AM - 12PM</td>
                </tr>
                <tr className="table-row">
                  <th className="table-head" scope="row">
                    Sat:
                  </th>
                  <td className="table-data">9AM - 8PM</td>
                </tr>
                <tr className="table-row">
                  <th className="table-head" scope="row">
                    Sun:
                  </th>
                  <td className="table-data">Closed</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="footer-list">
            <p className="footer-list-title">Newsletter</p>
            <p className="newsletter-text">
              {/* Authoritatively morph 24/7 potentialities with error-free
              partnerships. */}
            </p>
          
          </div>
        </div>
      </div>
    </div>
    <div className="footer-bottom">
      <div className="container">
        <p className="copyright">
          © 2024
          <a style={{textDecoration: 'none'}} href="#" className="copyright-link">
           Taitlux
          </a>
          . All Rights Reserved
        </p>
      </div>
    </div>
  </footer>
  
  )
}

export default Footer
