import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Shop from "./Pages/Shop";
import ShopCategory from "./Pages/ShopCategory";
import Cart from "./Pages/Cart";

import Product from "./Pages/Product";
import Footer from "./Components/Footer/Footer";
import men_banner from "./Components/Assets/banner_mens.png";
import women_banner from "./Components/Assets/banner_women.png";
import kid_banner from "./Components/Assets/banner_kids.png";
function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Shop />} />
          <Route
           exact
            path="/shirt"
            element={<ShopCategory banner={men_banner} category="footwear" />}
          />
          <Route
            path="/footwear"
            exact
            element={<ShopCategory banner={women_banner} category="shirt" />}
          />
          <Route
           exact
            path="/wristwatch"
            element={<ShopCategory banner={kid_banner} category="wristwatch" />}
          />
          <Route
            path="/others"
            element={<ShopCategory banner={kid_banner} category="other" />}
          />
          <Route path="/product" element={<Product />} />
          <Route path="/product/:productId" element={<Product />} />
          <Route path="/cart" element={<Cart />} />
          {/* <Route path="/watches" element={<Cart />} /> */}
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
