import p12_img from "./s12.jpg";
import p15_img from "./f3.jpg";
import p8_img from "./s8.jpg";
import p4_img from "./s4.jpg";
import p19_img from "./f7.jpg";
import p2_img from "./af1.jpg";
import p20_img from "./f8.jpg";
import p24_img from "./f12.jpg";

let new_collections = [
  {
    id: 12,
    name: "BOXY-FIT KNIT T-SHIRT",
    image: p12_img,
    new_price: 135.0,
  },
  {
    id: 15,
    name: "Under armour men ignite select side",
    image: p15_img,
    new_price: 105.0,
  },
  {
    id: 8, 
    name: "TEXTURED WEAVE QUARTER-ZIP POLO SHIRT",
    image: p8_img,
    new_price: 150.0,
  },
  {
    id: 4,
    name: "EMBROIDED KNIT T-SHIRT",
    image: p4_img,

    new_price: 148.0,
  },
  {
    id: 19,
    name: "Puma army trainer suede in brown",
    image: p19_img,
    new_price: 110.0,
  },
  {
    id: 2,
    name: "EMBROIDED KNIT T-SHIRT",
    image: p2_img,
    new_price: 125.0,
  },
  {
    id: 20,
    name: "ASOS DESIGN chunky mule loafers with western buckle-Black",
    image: p20_img,
    new_price: 150.0,
  },
  {
    id: 24,
    name: "Men's Leather Chunky Sole Trainers",
    image: p24_img,
    new_price: 150.0,
  },
];

export default new_collections;
