import React, {useContext} from 'react'
import { ShopContext } from '../Context/ShopContext'
import Breadcrum from '../Components/Breadcrum/Breadcrum'
import { useParams } from 'react-router-dom'
import ProductDisplay from '../Components/ProductDisplay/ProductDisplay'
import DescriptionBox from '../Components/DescriptionBox/DescriptionBox'
import RelatedProducts from '../Components/RelatedProducts/RelatedProducts'
const Product = () => {
const {all_products} = useContext(ShopContext)
const {productId} = useParams()
const product = all_products.find((e)=>e.id === Number(productId) )
  return (
    <div>
      {/* <Breadcrum product={product}/> */}
      <br />
      <br />
      <ProductDisplay product={product}/>
     

      <DescriptionBox product={product}  showDescription={product.category === 'footwear'} />
      <RelatedProducts/>
    </div>
  )
}

export default Product
