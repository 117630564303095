import p1_img from "./s1.jpg";
import p2_img from "./af1.jpg";
import p3_img from "./s3.jpg";
import p4_img from "./s4.jpg";
import p5_img from "./s5.jpg";
import p6_img from "./s6.jpg";
import p7_img from "./af2.jpg";
import p8_img from "./s8.jpg";
import p9_img from "./s9.jpg";
import p10_img from "./s10.jpg";
import p11_img from "./af3.jpg";
import p12_img from "./s12.jpg";
import p13_img from "./f1.jpg";
import p14_img from "./f2.jpg";
import p15_img from "./f3.jpg";
import p16_img from "./f4.jpg";
import p17_img from "./f5.jpg";
import p18_img from "./f6.jpg";
import p19_img from "./f7.jpg";
import p20_img from "./f8.jpg";
import p21_img from "./f9.jpg";
import p22_img from "./f10.jpg";
import p23_img from "./f11.jpg";
import p24_img from "./f12.jpg";
import p25_img from "./w1.jpg";
import p26_img from "./w2.jpg";
import p27_img from "./w3.jpg";
import p28_img from "./w4.jpg";
import p29_img from "./w5.jpg";
import p30_img from "./w6.jpg";
import p31_img from "./w7.jpg";
import p32_img from "./w8.jpg";
import p33_img from "./w9.jpg";
import p34_img from "./w10.jpg";
import p35_img from "./w11.jpg";
import p36_img from "./w12.jpg";

let all_product = [
  {
    id: 1,
    name: "SLIM FIT KNIT T-SHIRT",
    category: "footwear",
    desc: "  A captivating fusion of tradition and modernity, this custom-made luxury wear embodies the essence of Nigerian culture. Crafted by a talented Nigerian.",
    description:'Wide round neck and short sleeves.',
    image: p1_img,
    new_price: 150.0
  },
  {
    id: 2,
    name: "EMBROIDERED KNIT T-SHIRT",
    category: "footwear",
    desc: "  A captivating fusion of tradition and modernity, this custom-made luxury wear embodies the essence of Nigerian culture. Crafted by a talented Nigerian.",
    description:'',
    image: p2_img,
    sold: "Sold Out" ,
  },
  {
    id: 3, 
    name: "SLOGAN PRINT T-SHIRT",
    category: "footwear",
    desc: "  A captivating fusion of tradition and modernity, this custom-made luxury wear embodies the essence of Nigerian culture. Crafted by a talented Nigerian.",
    description:'Loose-fitting sweatshirt with a round neck and short sleeves. Contrast slogan print on the front and a printed patch on the back. Ribbed trims.',
    image: p3_img,
    new_price: 130.0
  },
  {
    id: 4, 
    name: "EMBROIDERED KNIT T-SHIRT",
    desc: "  A captivating fusion of tradition and modernity, this custom-made luxury wear embodies the essence of Nigerian culture. Crafted by a talented Nigerian.",
    category: "footwear",
    description:"Boxy fit T-shirt made of spun cotton fabric. Round neck and short sleeves. Contrast embroidered slogan detail on the front.",
    image: p4_img,
    new_price: 148.0
  },
  {
    id: 5, 
    name: "FADED T-SHIRT WITH SLOGAN",
    desc: "  A captivating fusion of tradition and modernity, this custom-made luxury wear embodies the essence of Nigerian culture. Crafted by a talented Nigerian.",
    category: "footwear",
    description:"Round neck short sleeve T-shirt. Contrast prints on the front. Washed effect.",
    image: p5_img,
    new_price: 124.0
  },
  {
    id: 6,
    name: "STRIPED KNIT T-SHIRT",
    category: "footwear",
    desc: "  A captivating fusion of tradition and modernity, this custom-made luxury wear embodies the essence of Nigerian culture. Crafted by a talented Nigerian.",
    description:"",
    image: p6_img,
    new_price: 115.0
  },
  {
    id: 7,
    name: "STRIPED KNIT T-SHIRT",
    category: "footwear",
    desc: "  A captivating fusion of tradition and modernity, this custom-made luxury wear embodies the essence of Nigerian culture. Crafted by a talented Nigerian.",
    image: p7_img,
    new_price: 150.0
  },
  {
    id: 8,
    name: "TEXTURED WEAVE QUARTER-ZIP POLO SHIRT",
    category: "footwear",
    desc: "  A captivating fusion of tradition and modernity, this custom-made luxury wear embodies the essence of Nigerian culture. Crafted by a talented Nigerian.",
    description:"Polo shirt with zip fastening on the front.Short sleeves. Ribbed trims",
    image: p8_img,
    new_price: 150.0
  },
  {
    id: 9,
    name: "TEXTURED T-SHIRT",
    category: "footwear",
    desc: "  A captivating fusion of tradition and modernity, this custom-made luxury wear embodies the essence of Nigerian culture. Crafted by a talented Nigerian.",
    description:"",
    image: p9_img,
    new_price: 130.0
  },
  {
    id: 10,
    name: "TEXTURE WEAVE  T-SHIRT",
    category: "footwear",
    desc: "  A captivating fusion of tradition and modernity, this custom-made luxury wear embodies the essence of Nigerian culture. Crafted by a talented Nigerian.",
    description:"",
    image: p10_img,
    new_price: 150.0
  },
  {
    id: 11,
    name: "FADED T-SHIRT WITH SLOGAN",
    category: "footwear",
    desc: "  A captivating fusion of tradition and modernity, this custom-made luxury wear embodies the essence of Nigerian culture. Crafted by a talented Nigerian.",
    description:"",
    image: p11_img,
    new_price: 110.0
  },
  {
    id: 12,
    name: "BOXY-FIT KNIT T-SHIRT",
    category: "footwear",
    desc: "  A captivating fusion of tradition and modernity, this custom-made luxury wear embodies the essence of Nigerian culture. Crafted by a talented Nigerian.",
    description:"",
    image: p12_img,
    new_price: 135.0
  },
  {
    id: 13,
    name: "Split leather moc toe loafers",
    category: "shirt",
    image: p13_img,
    new_price: 120.0
  },
  {
    id: 14,
    name: "Slip Suede Loafers",
    category: "shirt",
    image: p14_img,
    new_price: 130.0
  },
  {
    id: 15,
    name: "Under armour men ignite select side",
    category: "shirt",
    image: p15_img,
    new_price: 105.0
  },
  {
    id: 16,
    name: "Men's slide IDR",
    category: "shirt",
    image: p16_img,
    new_price: 100.0
  },
  {
    id: 17,
    name: "Bershika Men's deconstructed sneakers with keyring detail Men",
    category: "shirt",
    image: p17_img,
    new_price: 150.0
  },
  {
    id: 18,
    name: "Nike dunk low midnight navy",
    category: "shirt",
    image: p18_img,
    new_price: 125.0
  },
  {
    id: 19,
    name: "Puma army trainer suede in brown",
    category: "shirt",
    image: p19_img,
    new_price: 110.0
  },
  {
    id: 20,
    name: "ASOS DESIGN chunky mule loafers with western buckle-Black",
    category: "shirt",
    image: p20_img,
    new_price: 150.0
  },
  {
    id: 21,
    name: "Oliver Slide Sandals - Light Grey",
    category: "shirt",
    image: p21_img,
    new_price: 100.0
  },
  {
    id: 22,
    name: "Nike dunk low retro samba 2020",
    category: "shirt",
    image: p22_img,
    new_price: 120.0
  },
  {
    id: 23,
    name: "Dr Martens Mens Arian Tassel Loafer Shoes - khaki",
    category: "shirt",
    image: p23_img,
    new_price: 140.0
  },
  {
    id: 24,
    name: "Men's Leather Chunky Sole Trainers",
    category: "shirt",
    image: p24_img,
    new_price: 150.0
  },
  {
    id: 25,
    name: "Boys Orange Colourblocked Hooded Sweatshirt",
    category: "wristwatch",
    image: p25_img,
    new_price: 110.0
  },
  {
    id: 26,
    name: "Boys Orange Colourblocked Hooded Sweatshirt",
    category: "wristwatch",
    image: p26_img,
    new_price: 112.0
  },
  {
    id: 27,
    name: "Boys Orange Colourblocked Hooded Sweatshirt",
    category: "wristwatch",
    image: p27_img,
    new_price: 138.0
  },
  {
    id: 28,
    name: "Boys Orange Colourblocked Hooded Sweatshirt",
    category: "wristwatch",
    image: p28_img,
    new_price: 140.0
  },
  {
    id: 29,
    name: "Boys Orange Colourblocked Hooded Sweatshirt",
    category: "wristwatch",
    image: p29_img,
    new_price: 153.0
  },
  {
    id: 30,
    name: "Boys Orange Colourblocked Hooded Sweatshirt",
    category: "wristwatch",
    image: p30_img,
    new_price: 118.0
  },
  {
    id: 31,
    name: "Boys Orange Colourblocked Hooded Sweatshirt",
    category: "wristwatch",
    image: p31_img,
    new_price: 148.0
  },
  {
    id: 32,
    name: "Boys Orange Colourblocked Hooded Sweatshirt",
    category: "wristwatch",
    image: p32_img,
    new_price: 144.0
  },
  {
    id: 33,
    name: "Boys Orange Colourblocked Hooded Sweatshirt",
    category: "wristwatch",
    image: p33_img,
    new_price: 118.0
  },
  {
    id: 34,
    name: "Boys Orange Colourblocked Hooded Sweatshirt",
    category: "wristwatch",
    image: p34_img,
    new_price: 150.0
  },
  {
    id: 35,
    name: "Boys Orange Colourblocked Hooded Sweatshirt",
    category: "wristwatch",
    image: p35_img,
    new_price: 138.0
  },
  {
    id: 36,
    name: "Boys Orange Colourblocked Hooded Sweatshirt",
    category: "wristwatch",
    image: p36_img,
    new_price: 140.0
  },
];

export default all_product;
