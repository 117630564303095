import React from 'react'
import './Item.css'
import { Link } from 'react-router-dom';
const Item = (props) => {
  return (
    <div className='item'>
      
       <p style={{color:'red', fontWeight:'500', fontSize:'20px', textDecoration:'Highlight'} }>{props.sold}</p>
      <Link to={`/product/${props.id}`}>
      <img onClick={window.scrollTo(0,0)} src={props.image} alt="" />
      </Link>
      <p>{props.name}</p>
      <div className="item-prices">
        <div className="item-price-new">
        ${props.new_price} 
       
        </div>
       
      </div>
    </div>
  )
}

export default Item
