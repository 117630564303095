import p14_img from "./f2.jpg";
import p5_img from "./s5.jpg";
import p23_img from "./f11.jpg";
import p3_img from "./s3.jpg";
let data_product = [
  {
    id:14,
    name:"Slip Suede Loafers",
    image:p14_img,
    new_price: 130.0
    
  },
  {id:5,
    name:"FADED T-SHIRT WITH SLOGAN",
    image:p5_img,
    new_price: 124.0
  },
  {id:23,
    name:"Dr Martens Mens Arian Tassel Loafer Shoes - khaki",
    image:p23_img,
    new_price: 140.0
  },
  {id:3,
    name:"SLOGAN PRINT T-SHIRT",
    image:p3_img,
    new_price: 130.0
  },
];

export default data_product;
