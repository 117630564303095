import React, { useContext, useState } from 'react';
import './ProductDisplay.css';
import star_icon from '../Assets/star_icon.png';
import star_dull_icon from '../Assets/star_dull_icon.png';
import { ShopContext } from '../../Context/ShopContext';

const ProductDisplay = (props) => {
  const { product } = props;
  const { addToCart } = useContext(ShopContext);
  const [selectedSize, setSelectedSize] = useState(null);

  const handleSizeSelect = (size) => {
    setSelectedSize(size);
  };

  return (
    <div className='productdisplay'>
      <div className='productdisplay-left'>
        <div className="productdisplay-img-list">
          <img src={product.image} alt="" />
          <img src={product.image} alt="" />
          <img src={product.image} alt="" />
          <img src={product.image} alt="" />
        </div>
        <div className="productdisplay-img">
          <img className='productdisplay-main-img' src={product.image} alt="" />
        </div>
      </div>
      <div className='productdisplay-right'>
        <h1>{product.name}</h1>

        <div className="productdisplay-right-stars">
          <img src={star_icon} alt="" />
          <img src={star_icon} alt="" />
          <img src={star_icon} alt="" />
          <img src={star_icon} alt="" />
          <img src={star_icon} alt="" />
          <p>(5)</p>
        </div>
        <h3 style={{ color: 'red', fontWeight: '500', fontSize: '20px', textDecoration: 'Highlight' }}>{product.sold}</h3>
        <div className="productdisplay-right-prices"></div>
        <div className="productdisplay-description">
        {product.desc}
        </div>
        <div className="productdisplay-right-size">
          <h1>Select Size</h1>
          <div className="productdisplay-right-sizes">
            <div onClick={() => handleSizeSelect('S')} className={`size ${selectedSize === 'S' ? 'selected' : ''}`}>S</div>
            <div onClick={() => handleSizeSelect('M')} className={`size ${selectedSize === 'M' ? 'selected' : ''}`}>M</div>
            <div onClick={() => handleSizeSelect('L')} className={`size ${selectedSize === 'L' ? 'selected' : ''}`}>L</div>
            <div onClick={() => handleSizeSelect('XL')} className={`size ${selectedSize === 'XL' ? 'selected' : ''}`}>XL</div>
          </div>
        </div>
        <button onClick={() => { addToCart(product.id, selectedSize) }}>
          ADD TO CART
        </button>
      </div>
    </div>
  );
};

export default ProductDisplay;
