import React, { useContext, useRef, useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import logo from "../Assets/logo.png";
import cart_icon from "../Assets/cart_icon.png";
import { ShopContext } from "../../Context/ShopContext";
import nav_dropdown_icon from "../Assets/drop.png";
const Navbar = () => {
  const [menu, setMenu] = useState("shop");
  const{getTotalCartItems} = useContext(ShopContext);
  const menuRef = useRef();
  const dropdown_toggle = (e) => {
    menuRef.current.classList.toggle("nav-menu-visible");
    e.target.classList.toggle("open");
  }
  return (
    <div className="navbar">
      <div className="nav-logo">
        <img src={logo} alt="" />
        <p>Tiatlux</p>
      </div>
      <img className="nav-dropdown" onClick={dropdown_toggle}  src={nav_dropdown_icon} alt="" />
      <ul ref={menuRef} className="nav-menu">
        <li
          onClick={() => {
            setMenu("shop");
          }}
        >
          <Link style={{ textDecoration: "none" }} to="/">
            Shop
          </Link>{" "}
          {menu === "shop" ? <hr /> : <></>}{" "}
        </li>
        <li
          onClick={() => {
            setMenu("Footwears");
          }}
        >
          <Link style={{ textDecoration: "none" }} to="/shirt">
           Shirt
          </Link>{" "}
          {menu === "Footwears" ? <hr /> : <></>}
        </li>
       
        <li
          onClick={() => {
            setMenu(" Shirt");
          }}
        >
          <Link style={{ textDecoration: "none" }} to="/footwear">
          Footwear
          </Link>{" "}
          {menu === " Shirt" ? <hr /> : <></>}
        </li>
       
       
      </ul>
      <div className="nav-login-cart">
        <Link  to="/cart">
          <img src={cart_icon} alt="" />
        </Link>
        <div className="nav-cart-count">{getTotalCartItems()}</div>
      </div>
      {/* <div  className="nav-login-cart">
      <Link style={{ textDecoration: "none", fontSize:'22px' }} to="/others">
          Others
          </Link>{" "}
          {menu === "Watches" ? <hr /> : <></>}
       
      </div> */}
    </div>
  );
};

export default Navbar;
